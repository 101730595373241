import React from "react";
import Router from "./router";
import ReloadModal from "../components/reloadModal";

const App = () => {
  return (
    <>
      <ReloadModal />
      <Router />
    </>
  );
};

export default App;
