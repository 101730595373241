import { combineReducers } from "redux";
import user from "./user";
import quickSearch from "./quickSearch";
import menu from "./menu";

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
  toastr: toastrReducer, // <- Mounted at toastr.
  user,
  menu,
  quickSearch,
});

export default rootReducer;
