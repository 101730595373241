import axios from "axios";
import apiUrls from "../../config";

export const bucketFileUpload = async (
  bucket,
  config,
  file,
  filename,
  progressCallback
) => {
  const fileType = filename.split(".")[1];
  const bucketAccessResponse = await axios.post(
    `${apiUrls.FILE_URL}/bucket-access`,
    {
      filename,
      fileType,
      bucket,
      operation: "putObject",
    },
    config
  );
  const signedRequest = bucketAccessResponse.data.signedRequest;
  const options = {
    headers: {
      "Content-Type": fileType,
    },
    onUploadProgress: (progressEvent) =>
      progressCallback(progressEvent.loaded / progressEvent.total),
  };
  await axios.put(signedRequest, file, options);
};

export const logClientError = async (props) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const response = await axios.post(
      `${apiUrls.NOTIFICATION_URL}/send-client-error`,
      props,
      config
    );
    return response.data;
  } catch (e) {
    console.log("Error:", e);
  }
};

export const sysRruleToText = async (payload) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const response = await axios.post(
      `${apiUrls.UTILITY_URL}/rrule-to-text`,
      payload,
      config
    );
    return response.data;
  } catch (e) {
    console.log("Error:", e);
  }
};

export const getStates = async (payload) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const response = await axios.post(
      `${apiUrls.UTILITY_URL}/get-states`,
      payload,
      config
    );
    return response.data;
  } catch (e) {
    console.log("Error:", e);
  }
};
