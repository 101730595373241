import React from "react";

const ErrorLayout = ({ children }) => {
  return (
    <div className="error-layout">
      <main className="main">{children}</main>
    </div>
  );
};

export default ErrorLayout;
