import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ReduxToastr from "react-redux-toastr";
import "./index.scss";
import Spinner from "./components/spinner/spinner";
import App from "./app/app";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <App store={store} />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="bounceInDown"
        transitionOut="bounceOutUp"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
