import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import union from "lodash/union";
import { ChevronRight } from "react-feather";
import { NavLink } from "react-router-dom";
import * as faIcons from "react-icons/fa";
import "../../../../assets/scss/components/sidebar/sidemenu/sidemenu.scss";
import SideMenu from "../sidemenuHelper";

class SideMenuContent extends Component {
  state = {};

  checkIsActive(match, location, urls) {
    let found = false;
    // eslint-disable-next-line
    for (const u in urls) {
      if (location.pathname.indexOf(urls[u]) > -1) {
        found = true;
        break;
      }
    }
    if ((match || {}).isExact || found) {
      return true;
    }
    return false;
  }

  checkMultiMenuOpen(link, urls) {
    let found = false;
    if (link === this.props.location.pathname) {
      found = true;
    }
    // eslint-disable-next-line
    for (const u in urls) {
      if (this.props.location.pathname.indexOf(urls[u]) > -1) {
        found = true;
        break;
      }
    }
    return found;
  }

  renderMenu(menuItems) {
    const userRoles = !this.props.user.roles ? [] : this.props.user.roles;
    const menu = [];
    let defaultSelectedItem = -1;
    // eslint-disable-next-line
    for (const m in menuItems) {
      if (!menuItems[m].isHidden) {
        const subMenu = [];
        let topLevelRoles = [];
        if (
          !menuItems[m].subMenuLinks ||
          menuItems[m].subMenuLinks.length === 0
        ) {
          topLevelRoles = menuItems[m].roles;
          if (
            userRoles.filter((value) => topLevelRoles.includes(value)).length >
              0 ||
            topLevelRoles.includes("*")
          ) {
            const MenuIcon = faIcons[menuItems[m].icon];
            const { relatedLinks, link, name } = menuItems[m];
            menu.push(
              <SideMenu.MenuSingleItem key={m}>
                <NavLink
                  to={link}
                  activeClassName="active"
                  isActive={(match, location) =>
                    this.checkIsActive(match, location, relatedLinks)
                  }
                >
                  {MenuIcon && (
                    <i className="menu-icon">
                      <MenuIcon size={18} color="white" />
                    </i>
                  )}
                  <span className="menu-item-text text-white">{name}</span>
                </NavLink>
              </SideMenu.MenuSingleItem>
            );
          }
        } else {
          // eslint-disable-next-line
          for (const s in menuItems[m].subMenuLinks) {
            const { link, relatedLinks, name, roles } = menuItems[
              m
            ].subMenuLinks[s];
            topLevelRoles = union(topLevelRoles, roles);
            if (
              userRoles.filter((value) => roles.includes(value)).length > 0 ||
              roles.includes("*")
            ) {
              if (this.checkMultiMenuOpen(link, relatedLinks)) {
                defaultSelectedItem = menu.length;
              }
              subMenu.push(
                <NavLink
                  key={s}
                  to={{ pathname: link }}
                  exact
                  className="item"
                  activeclassname="active"
                  isActive={(match, location) =>
                    this.checkIsActive(match, location, relatedLinks)
                  }
                >
                  <span className="menu-item-text">{name}</span>
                </NavLink>
              );
            }
          }
          if (
            userRoles.filter((value) => topLevelRoles.includes(value)).length >
            0
          ) {
            const MenuIcon = faIcons[menuItems[m].icon];
            menu.push(
              <SideMenu.MenuMultiItems
                key={m}
                name={menuItems[m].name}
                Icon={<MenuIcon size={18} />}
                ArrowRight={<ChevronRight size={16} />}
                collapsedSidebar={this.props.collapsedSidebar}
              >
                {subMenu}
              </SideMenu.MenuMultiItems>
            );
          }
        }
      }
    }
    return (
      <SideMenu
        className="sidebar-content"
        toggleSidebarMenu={this.props.toggleSidebarMenu}
        defaultSelectedItem={defaultSelectedItem}
      >
        {menu}
      </SideMenu>
    );
  }

  render() {
    return this.renderMenu(this.props.menu);
  }
}

function mapStateToProps(state) {
  return {
    user: state.user || {},
    menu: (state.user || {}).menu || [],
  };
}

export default connect(mapStateToProps)(withRouter(SideMenuContent));
