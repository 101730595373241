import React, { Component } from "react";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";

class ReloadModal extends Component {
  state = {
    show: false
  };

  componentDidMount() {
    window.addEventListener("ChunkLoadError", () => {
      this.setState({
        show: true
      });
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.show} size="md" backdrop="static">
        <ModalBody>
          <Row className="pt-3">
            <Col className="text-center">
              <h1>New Application Version Available</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h4>Please click reload to update automatically.</h4>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col className="text-center">
              <Button color="primary" size="lg" onClick={() => window.location.reload(window.location.href)}>
                Reload
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default ReloadModal;
