import React, { Component, createContext } from "react";
import { connect } from "react-redux";
import { sidebarCollapsed } from "../../redux/actions";

const FoldedContentContext = createContext();

class FoldedContentProvider extends Component {
  state = {
    foldedContent: this.props.collapsed,
    makeFullContent: () => {
      this.props.sidebarCollapsed(true);
      this.setState(() => ({
        foldedContent: true,
      }));
    },
    makeNormalContent: () => {
      this.props.sidebarCollapsed(false);
      this.setState(() => ({
        foldedContent: false,
      }));
    },
  };

  render() {
    return (
      <FoldedContentContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </FoldedContentContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  collapsed: state.menu.collapsed,
});

const FoldedContentConsumer = FoldedContentContext.Consumer;

// eslint-disable-next-line no-class-assign
FoldedContentProvider = connect(mapStateToProps, { sidebarCollapsed })(
  FoldedContentProvider
);

export { FoldedContentProvider, FoldedContentConsumer };
