const defaultState = {
  searchTerm: "",
  loading: false,
  counts: {},
  hasResults: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "QUICK_SEARCH":
      return { ...state, ...action };
    default:
      return state;
  }
};
