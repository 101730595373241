import axios from "axios";
import config from "./config";
import { store } from "./redux/store";

const axiosInstance = axios.create({
  baseURL: config.API_URL
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.response.status === 401) {
      localStorage.clear();
      store.dispatch({ type: "CLEAR_USER" });
    }
    return Promise.reject(error);
  }
);

export const postDataAPI = async (uri, payload, config) => {
  const defaultConfig = {
    headers: { Authorization: localStorage.getItem("token") },
    ...config
  };
  const response = await axiosInstance.post(uri, payload, defaultConfig);
  return response.data;
};
