// import external modules
import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Spinner from "../components/spinner/spinner";

// import internal(own) modules
import MainLayoutRoutes from "../layouts/routes/mainRoutes";
import FullPageLayout from "../layouts/routes/fullpageRoutes";
import ErrorLayoutRoute from "../layouts/routes/errorRoutes";

import RequireAuth from "../components/RequireAuth";
import ScrollToTop from "../components/scrollToTop";
import ErrorPage from "../views/error";

const appRoutes = [
  {
    Layout: FullPageLayout,
    path: "/login",
    Component: lazy(() => import("../views/login"))
  },
  {
    Layout: FullPageLayout,
    path: "/forgot-password",
    Component: lazy(() => import("../views/forgotPassword"))
  },
  {
    Layout: FullPageLayout,
    path: "/reset-password",
    Component: lazy(() => import("../views/resetPassword"))
  },
  {
    Layout: FullPageLayout,
    path: "/verify-email",
    Component: lazy(() => import("../views/verifyEmail"))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/",
    Component: RequireAuth(lazy(() => import("../views/dashboard")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/user-profile",
    Component: RequireAuth(lazy(() => import("../views/users/userProfile")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/users",
    Component: RequireAuth(lazy(() => import("../views/users/usersIndex")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/user-details",
    Component: RequireAuth(lazy(() => import("../views/users/userDetails")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/configs",
    Component: RequireAuth(lazy(() => import("../views/configManager/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/config-details",
    Component: RequireAuth(lazy(() => import("../views/configManager/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patient-info-lists",
    Component: RequireAuth(lazy(() => import("../views/patientInfoLists/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patient-info-list-details",
    Component: RequireAuth(lazy(() => import("../views/patientInfoLists/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/flowsheet-lists",
    Component: RequireAuth(lazy(() => import("../views/flowsheetLists/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/flowsheet-list-details",
    Component: RequireAuth(lazy(() => import("../views/flowsheetLists/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/bedside-pcs",
    Component: RequireAuth(lazy(() => import("../views/bedsidePCs/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/bedside-pc-details",
    Component: RequireAuth(lazy(() => import("../views/bedsidePCs/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/devices",
    Component: RequireAuth(lazy(() => import("../views/devices/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/device-details",
    Component: RequireAuth(lazy(() => import("../views/devices/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/clinical-operators",
    Component: RequireAuth(lazy(() => import("../views/clinicalOperators/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/clinical-operator-details",
    Component: RequireAuth(lazy(() => import("../views/clinicalOperators/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/translations",
    Component: RequireAuth(lazy(() => import("../views/translations/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/translation-details",
    Component: RequireAuth(lazy(() => import("../views/translations/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/reports",
    Component: RequireAuth(lazy(() => import("../views/reports/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/quick-search",
    Component: RequireAuth(lazy(() => import("../views/quickSearch/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/system-configs",
    Component: RequireAuth(lazy(() => import("../views/systemConfigs/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/system-config-details",
    Component: RequireAuth(lazy(() => import("../views/systemConfigs/details")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/location-configs",
    Component: RequireAuth(lazy(() => import("../views/locationConfigs/index")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/location-config-details",
    Component: RequireAuth(lazy(() => import("../views/locationConfigs/locationConfigDetails")))
  },
  {
    Layout: MainLayoutRoutes,
    path: "/location-config-value-details",
    Component: RequireAuth(lazy(() => import("../views/locationConfigs/locationValueDetails")))
  }
];

class Router extends Component {
  render() {
    const routeArr = [];
    appRoutes.forEach(r => {
      const { Layout, Component } = r;
      routeArr.push(
        <Layout
          key={r.path}
          exact
          path={r.path}
          render={matchprops => (
            <Suspense fallback={<Spinner />}>
              <Component {...matchprops} key={matchprops.location.key} />
            </Suspense>
          )}
        />
      );
    });
    return (
      // Set the directory path if you are deplying in sub-folder
      <BrowserRouter basename="/">
        <>
          <ScrollToTop />
          <Switch>
            {routeArr}
            <ErrorLayoutRoute
              exact
              path="/error"
              render={matchprops => (
                <Suspense fallback={<Spinner />}>
                  <ErrorPage {...matchprops} />
                </Suspense>
              )}
            />
            <ErrorLayoutRoute
              render={matchprops => (
                <Suspense fallback={<Spinner />}>
                  <ErrorPage {...matchprops} />
                </Suspense>
              )}
            />
          </Switch>
        </>
      </BrowserRouter>
    );
  }
}

export default Router;
